import axios from 'api/axiosController';
import { getAccessToken } from "utils/auth";

export const approveSeller = async (data: any) => {
  let uri = `/admin/seller-approve`;
  try {
    const res = await axios.post(uri, data, { headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const rejectSeller = async (data: any) => {
  let uri = `/admin/seller-disapprove`;
  try {
    const res = await axios.post(uri, data, { headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const signinAdmin = async (data: any) => {
  let uri = `/admin/signin`;
  console.log(data)
  try {
    const res = await axios.post(uri, data);
    return res.data;
  } catch (e) {
    throw e;
  }
};
