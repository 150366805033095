import axios from 'api/axiosController';
import { getAccessToken } from "utils/auth";

export const getSellers = async (page:number) => {
  console.log(page)
  let uri = `/admin/sellers?page=${page}`;
  try {
    const res = await axios.get(uri, { headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
    return res.data;
  } catch (e) {
    throw e;
  }
};
