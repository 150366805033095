import { call, put, takeLatest } from 'redux-saga/effects';
import snack from 'components/wrapper/snack';
import {
  adminSigninStart,
  adminSigninSuccess,
  adminSigninFailure,
  adminSignOutStart,
  adminSignOutSuccess,
  approveSellerStart,
  approveSellerSuccess,
  approveSellerFailure,
  rejectSellerStart,
  rejectSellerSuccess,
  rejectSellerFailure
} from 'redux/reducers/admin';
import {
  approveSeller,
  rejectSeller,
  signinAdmin
} from 'redux/services/admin';
import {
  clearAccessToken,
  clearRefreshToken,
  setAccessToken,
  setRefreshToken
} from 'utils/auth';

function* adminSagaWatcher() {
  yield takeLatest(
    [
      adminSigninStart.type,
      adminSignOutStart.type,
      approveSellerStart.type,
      rejectSellerStart.type
    ],
    adminWorker
  );
}

function* adminWorker(action: any): any {
  try {
    switch (action.type) {
      case adminSigninStart.type:
        {
          const response: any = yield call(signinAdmin, action.payload.seller);
          const { navigate } = action.payload;
          yield setAccessToken(response.ResponseBody.token);
          yield setRefreshToken(response.ResponseBody.refreshToken);
          yield put(
            adminSigninSuccess({
              admin: response.ResponseBody,
              token: response.ResponseBody.token
            })
          );
          navigate('/');
        }
        break;
      case adminSignOutStart.type:
        {
          clearAccessToken();
          clearRefreshToken();
          yield put(adminSignOutSuccess());
          const { navigate } = action.payload;
          navigate('/signin');
        }
        break;
      case approveSellerStart.type:
        {
          const response: any = yield call(approveSeller, action.payload);
          yield put(approveSellerSuccess());
          snack.success(`Successfully sent Approval Mail to ${action.payload.email}`);
        }
        break;
      case rejectSellerStart.type:
        {
          const response: any = yield call(rejectSeller, action.payload);
          yield put(rejectSellerSuccess());
          snack.info(`Successfully sent Rejection Mail to ${action.payload.email}`);
        }
        break;
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === adminSigninStart.type) {
      console.error(err?.response?.data?.msg);
      yield put(adminSigninFailure({ msg: err?.response?.data?.msg }));
    }

    if (action.type === approveSellerStart.type) {
      console.error(err?.response?.data?.msg);
      yield put(approveSellerFailure({ msg: err?.response?.data?.msg }));
    }

    if (action.type === rejectSellerStart.type) {
      console.error(err?.response?.data?.msg);
      yield put(rejectSellerFailure({ msg: err?.response?.data?.msg }));
    }
  }
}

export default adminSagaWatcher;
