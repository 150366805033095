import { createSlice } from '@reduxjs/toolkit';
import { getAccessToken } from 'utils/auth';

interface State {
  isLoading: boolean;
  error: boolean;
  sellers: any;
  count: number;
}

const initialState: State = {
  isLoading: true,
  error: false,
  sellers: [],
  count: 0
};

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    getSellerStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getSellerSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      sellers: action.payload.sellers,
      count: action.payload.count
    }),
    getSellerFailure: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false
    }),
  }
});

export const {
  getSellerStart,
  getSellerSuccess,
  getSellerFailure,
} = sellerSlice.actions;
export default sellerSlice.reducer;
