import axios from 'api/axiosController';

export const getCategoryData = async () => {
    let uri = '/api/v1/categories';
      try {
        const res = await axios.get(uri);
        return res.data;
      } catch (e) {
        throw e;
      }
  };
