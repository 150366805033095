import axios from 'api/axiosController';
import { getAccessToken } from "utils/auth";


export const getUsers = async (page:number) => {
  let uri = `/admin/users?page=${page}`;
  try {
    const res = await axios.get(uri, { headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
    return res.data;
  } catch (e) {
    throw e;
  }
};
