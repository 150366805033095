import './style.css'
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@mui/material/Pagination';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Grid,
  Typography,
  TableFooter
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserStart } from 'redux/reducers/user'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%'
  },
  tableContainer: {
    borderRadius: 15,
    margin: '10px 10px',
    maxWidth: '100%'
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.dark,
    color: '#303f9f'
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light)
  },
  name: {
    fontWeight: 'bold',
    color: theme.palette.secondary.dark
  },
  status: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: 'white',
    backgroundColor: 'grey',
    borderRadius: 8,
    padding: '3px 10px',
    display: 'inline-block'
  }
}));

function UserList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  let users = useSelector((state: any) => state.user.users);
  const count = useSelector((state: any) => state.user.count);
  const pageLimit = 10;
  let pageCount: any = Math.ceil(count/pageLimit);

  useEffect(() => {
    dispatch(getUserStart({ page }));
  }, [dispatch]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getUserStart({ page:value }));
  }

  return (
    <>
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>Name</TableCell>
            <TableCell className={classes.tableHeaderCell}>Phone Number</TableCell>
            <TableCell className={classes.tableHeaderCell}>Role</TableCell>
            <TableCell className={classes.tableHeaderCell}>Email Verified</TableCell>
            <TableCell className={classes.tableHeaderCell}>Country Code</TableCell>
            {/* <TableCell className={classes.tableHeaderCell}>Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length > 0 &&
            users.map((users: any) => (
              <TableRow key={users._id}>
                {/* <TableCell>
                  <Typography color='primary' variant='subtitle2'>
                    {users.userId}
                  </Typography>
                </TableCell> */}
                <TableCell>
                  <Grid container>
                    <Grid item lg={2}>
                      <Avatar
                        alt={users.firstName}
                        src='.'
                        className={classes.avatar}
                      />
                    </Grid>
                    <Grid item lg={10}>
                      <Typography className={classes.name}>
                        {users.firstName} {' '} {users.lastName}
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        {users.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography color='primary' variant='subtitle2'>
                    {users.phoneNumber}
                  </Typography>
                </TableCell>
                <TableCell>{users.role}</TableCell>
                <TableCell>{users.isEmailVerified ? "Yes" : "No" }</TableCell>
                <TableCell>
                {users.countryCode}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter></TableFooter>
      </Table>
    </TableContainer>
    <Pagination count={pageCount} page={page} color="primary" onChange={handleChangePage}/>
    </>
  );
}

export default UserList;
