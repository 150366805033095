import { createSlice } from '@reduxjs/toolkit';
import { getAccessToken } from 'utils/auth';

interface State {
  isLoading: boolean;
  error: boolean;
  admin: object;
  sellers: any;
  accessToken?: string | null;
}

const initialState: State = {
  isLoading: true,
  error: false,
  admin: {},
  sellers: [],
  accessToken: getAccessToken() || null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    adminSigninStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      error: false,
    }),
    adminSigninSuccess: (state: State, action) => ({
      ...state,
      admin: action.payload.admin,
      isLoading: false,
      accessToken: action.payload.token
    }),
    adminSigninFailure: (state: State, action) => ({
      ...state,
      error: true,
      msg: action.payload.msg,
      isLoading: false
    }),
    adminSignOutStart: (state: State, action) => ({
      ...state,
      error: false,
    }),
    adminSignOutSuccess: (state: State) => ({
      ...state,
      error: false,
      accessToken: ''
    }),
    adminSignOutFailure: (state: State, action) => ({
      ...state,
      error: true,
      msg: action.payload.msg,
    }),
    approveSellerStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    approveSellerSuccess: (state: State) => ({
      ...state,
      isLoading: false
    }),
    approveSellerFailure: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false
    }),
    rejectSellerStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    rejectSellerSuccess: (state: State) => ({
      ...state,
      isLoading: false
    }),
    rejectSellerFailure: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false
    })
  }
});

export const {
  adminSigninStart,
  adminSigninSuccess,
  adminSigninFailure,
  adminSignOutStart,
  adminSignOutSuccess,
  adminSignOutFailure,
  approveSellerStart,
  approveSellerSuccess,
  approveSellerFailure,
  rejectSellerStart,
  rejectSellerSuccess,
  rejectSellerFailure
} = adminSlice.actions;
export default adminSlice.reducer;
