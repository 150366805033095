import { call, put, takeLatest } from 'redux-saga/effects';
import snack from 'components/wrapper/snack';
import { getUserStart, getUserSuccess, getUserFailure } from 'redux/reducers/user';
import { getUsers } from 'redux/services/user';

function* sellerOnboardingSagaWatcher() {
  yield takeLatest([getUserStart.type], sellerOnboardingWorker);
}

function* sellerOnboardingWorker(action: any): any {
  try {
    switch (action.type) {
      case getUserStart.type: {
        const response: any = yield call(getUsers, action.payload.page);
        yield put(
          getUserSuccess({
            users: response.ResponseBody.users,
            count: response.ResponseBody.count
          })
        );
        
        break;
      }

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
    if (action.type === getUserStart.type) {
      console.error(err?.response?.data?.msg);
      yield put(getUserFailure({ msg: err?.response?.data?.msg }));
    }
  }
}

export default sellerOnboardingSagaWatcher;
