import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  users: [];
  count: number;
}

const initialState: State = {
  isLoading: true,
  error: false,
  users: [],
  count: 0
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getUserSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      users: action.payload.users,
      count: action.payload.count
    }),
    getUserFailure: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false
    })
  }
});

export const { getUserStart, getUserSuccess, getUserFailure } = userSlice.actions;
export default userSlice.reducer;
