import {
    call,
    put,
    takeLatest
  } from 'redux-saga/effects';
  import {
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    fetchCategoriesStart
  } from "redux/reducers/categories";
import { getCategoryData } from 'redux/services/categories';
  
  function* categorySagaWatcher() {
    yield takeLatest(
      [fetchCategoriesStart.type],
      categorySagaWorker,
    );
  }
  
  function* categorySagaWorker(action: any): any {
    try {
      switch (action.type) {
        case fetchCategoriesStart.type: {
          const data = yield call(getCategoryData);
          yield put(fetchCategoriesSuccess({
            categories: data
          }));
        }
          break;
  
        default:
          break;
      }
    } catch (err:any) {
      console.error(`Error occuring while calling an action ${action.type}`, err);
  
      if (action.type === fetchCategoriesStart.type) {
        yield put(fetchCategoriesFailure());
      }
    }
  }
  
  export default categorySagaWatcher;
  