import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Error = (props: any) => {
  let msg: string = 'Page not Found';
  let statusCode: number = 404;

  if (!props.msg && !props.statusCode) {
    msg = 'Page not Found';
    statusCode = 404;
  }

  return (
    <div style={{ display: 'grid', justifyContent: 'center', padding: 20 }}>
      <Paper
        sx={{ backgroundColor: '#E8E8E8', mx: '2px', width: '30vw', color: 'red' }}
        elevation={6}
      >
        <Box p={1}>
          <div>
            <h2>{statusCode}</h2>
            <h2>{msg}</h2>
          </div>
        </Box>
      </Paper>
    </div>
  );
};

export default Error;
