import { call, put, takeLatest } from "redux-saga/effects";
import snack from "components/wrapper/snack";
import {
  getSellerStart,
  getSellerSuccess,
  getSellerFailure,
} from "redux/reducers/seller";
import { getSellers } from "redux/services/seller";

function* sellerSagaWatcher() {
  yield takeLatest([getSellerStart.type], sellerWorker);
}

function* sellerWorker(action: any): any {
  try {
    switch (action.type) {
      case getSellerStart.type: {
        const response: any = yield call(getSellers, action.payload.page);
        yield put(
          getSellerSuccess({
            sellers: response.ResponseBody.sellers,
            count: response.ResponseBody.count,
          })
        );
      }
      break;
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
    if (action.type === getSellerStart.type) {
      console.error(err?.response?.data?.msg);
      yield put(getSellerFailure({ msg: err?.response?.data?.msg }));
    }
  }
}

export default sellerSagaWatcher;
