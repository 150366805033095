
import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  categoryList: any;
  selectedCategory: string;
}

const initialState : State = {
  isLoading: true,
  error: false,
  categoryList: [],
  selectedCategory: ''
};

export const categorySlice = createSlice ({
  name: 'categories',
  initialState,
  reducers: {
    fetchCategoriesStart: () => ({
      ...initialState,
      isLoading: true,
    }),
    fetchCategoriesSuccess: (state: State, action) => {
        console.log('action.payload',action.payload.categories.ResponseBody)
        return{
      ...state,
      categoryList: action && action.payload.categories.ResponseBody,
      isLoading: false,
    }},
    fetchCategoriesFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    fetchCategoriesList: (state, action) => ({
      ...state,
      isLoading: false,
      categoryList: action.payload,
    }),
    selectCatgory: (state, action) => ({
      ...state,
      selectedCategory: action.payload,
    })
  },
});

export const {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  fetchCategoriesList,
  selectCatgory
} = categorySlice.actions;
export default categorySlice.reducer;
