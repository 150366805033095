import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './components/wrapper/snack';
import { Pipeline, SearchProvider } from '@sajari/react-hooks';
const pipeline = new Pipeline(
  {
    account: '1640286447936078869',
    collection: 'paysfer-collection',
  },
  'app',
);
ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider hideIconVariant>
      <SnackbarUtilsConfigurator />
      <SearchProvider search={{ pipeline }}>
      <App />
      </SearchProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
